@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
  font-family: myFirstFont;
  src: url('./vogue/BebasNeue-Regular.otf');
}
html {
  scroll-behavior: smooth!important;
}
*{
  scroll-behavior: smooth!important;
}

body{
  overflow-x: hidden;
}
.owl-dots{
  display: none;
}
a{
  text-decoration: none;
}
.active{
  color: #fff !important;
}
.preheader{
  background-color:#191549;
  padding: 10px;
}

.newlogos img{
  width: 60px!important;
}
.bloglogo ul{
  padding-left: 0px;
}
.container{
  padding: 0px;
}
.nav-link:focus{
    color: #da2f37 ;
}
.footerrightmenu img{
  width: 62px;
  height: 62px;
}

.preheadingtext p{
  font-family: 'poppins';
  color: #fff;
  font-size: 13px;
  font-weight: 300;
  margin: 0px;
  text-align: center;
  line-height: 15px!important;
}
.react-loading-skeleton {
  --base-color: #34317cf7;
  --highlight-color: #4f4d8d;
}
.carousel-item {
  transition: opacity 1s ease-in-out;
}
.carousel-item.active {
  opacity: 1;
}
.carousel-item-next.carousel-item-left, 
.carousel-item-prev.carousel-item-right, 
.carousel-item-left.active, 
.carousel-item-right.active {
  opacity: 0;
}

.newarrow1
{
  width: 19px!important;
  position: absolute;
  margin-top: -18px;
  z-index: 999;
  right: 14px;
}

.nav-link:hover{
  color: #da2f37 ;
}
::-webkit-scrollbar {
  display: none;
}
.upkllogo{
  width:65px;
  position: relative;
  z-index: 999;
  margin-left: auto;
  display: block;
  margin-right: auto;
}

.nav-item a{
  font-family: 'myFirstFont';
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: 7px;
}
.desktopnone{
  display: none;
}
.navbar 
{ 
height: 70px;
padding: 10px 50px!important;
position: -webkit-sticky; /* For Safari */
            position: sticky;
            top: 0;
            background:linear-gradient(0deg, rgba(149,27,33,1) 0%, rgba(208,62,69,1) 52%, rgba(149,27,33,1) 98%);
            color: white;
            padding: 10px 0;
            text-align: center;
            z-index: 99999;
}

.mr-auto a{
  margin-left: 56px;
}
.ml-auto a{
  margin-right: 32px;
}


.banner-container {
  position: relative;
  z-index: -1;
  overflow-x: hidden;
}
.carousel-indicators{
  display: none;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background:linear-gradient(181deg, rgb(0 0 0 / 7%) 70%, rgb(124 36 11) 96%);
    /* background-image:linear-gradient(270deg, rgb(16 28 81 / 0%) 0%, rgb(42 44 129 / 28%) 100%); */
    /* Adjust opacity as needed */
}
.banner-image {
  width: 100%;
  display: block;
  z-index: 0;
}

.banner-caption {
  position: absolute;
  bottom: 10px;
  left:4%;
  /* transform: translate(-50%, -50%); */
  color: white;
  text-align: left;
  width: 54%;
}
.banner-caption h2{
  font-family: "Oswald", sans-serif;
  font-size: 42px;
  text-transform: capitalize;
}

.banner-caption p{
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  font-size: 14px;
  margin-top: 28px;

}
.line{
  width: 100px;
  height: 2px;
  background-color: #da2f37 ;
  position: absolute;
  margin-top: 10px;
}
.arrow{
  position: relative;
  margin-top: -10px;
  margin-left: 10px;
}



/* fixture */
.fixturessection  {
  padding: 40px 0px 40px 60px;
  background-color: #191549;
}

.daymatch{
  background-color:#691b02;
  color: #fff;
  padding: 10px;
}
.sponsorsection{
  padding: 40px 20px;
  background-color: #fff;
}
.sponsorsection .titlecaption{
  margin: 0px auto 60px !important;
  display: block;
}
.sponsorsection h2
{
  color: #551702!important;
}
.sponsorsection .title h6{
  text-align: center;
  color: #551702;
  font-family: "Oswald", sans-serif;
  font-size: 18px;

}
.photoauction
{
  border-radius: 5px!important;
  margin-bottom: 20px;
}
.sponsorsection .title img{
margin:30px  auto;
display: block;
width: 70%;

}
.mnone{
  display: block!important;
 }

.newsbottom{
  margin-top: 30px;
  /* margin-bottom: 20px; */

}
.footermenu{
  margin-top: 30px;
  margin-bottom: 30px;
}
.footermenu p{
  color: #fff;
  font-size: 14px;
  font-family: 'poppins';
  text-align: center;
  white-space: nowrap;
  margin: 0px;
  font-weight: 400;
}
.textrightarrow
{
  position: relative;
  left: 15px;
  top: 7px;
}

  .separaternews {
    height: 0.2px;
    width: 100%;
    background-color: #ffffff5c;
    margin-top: 10px;
    margin-bottom: 20px;
}
@media only screen and (max-width: 600px) {
.founderbox .row{
}

 .footerlogo p{
  font-size: 12px;
 }

 .footermenu p{
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
}
.desktopnone{
  display: block;
}
 .mnone{
  display: none;
 }
  .fixturessection  {
    padding: 30px 0px 30px 15px;
  }
  .banner-image{
    width: 100%;
  }
  .whoweareleft img{
    width: 80%;
  }
  .banner-caption h2{
    font-size: 24px;
  }
  .banner-caption{
    left:3%;
    width: 90%;
      bottom: -10px;
  }
  .arrow{
    width: 5%;
    margin-top: -6px;
  }   
  .blogheadng  .arrow {
    width: 5%;
    margin-top: -2px;
    margin-left: 4px;
}
  .playerstatssection, .tvsection{
    padding:25px  10px!important;
  }
  /* .newletter{
    padding: 15px!important;
  } */
  .pointtable{
    padding: 30px 15px!important;
    
  }
  .overlay{
    padding: 0px;
  }
  
.gsection{
  border-radius: 8px;
}
 

  .scorebox h5{
    font-size: 22px!important;
  }
  .fixturecenter h3{
    font-size: 16px!important;
  }
  .fixtureleft h3{
    font-size: 13px!important;
    width: 100%;
  }
  .loss,.win{
    width: 25px!important;
    height: 25px!important;
    line-height: 25px;
    font-family: 'poppins';
  }
  
  tr,th,td{
    white-space: nowrap;
    font-size: 14px;
    border: 0px;
  }
  .fixturecard h6{
    font-size: 22px;
  }

 
  .tablecontainer{
    overflow-x: auto;
    overflow-y: visible;
  }
  .table{
    margin-top: 0px;
    z-index: auto;
  }
  .stats h5{
    font-size: 14px!important;
  }
  .playername h5 {
    margin-top: 9px!important;
    font-size: 18px!important;
}
  .stats h3
  {
    font-size: 16px!important;
  }
  table{
    overflow-x: scroll;
    width: 100%;
  }
}


.titlecaption{
  width: fit-content;
  margin-bottom: 30px;
}
.titlecaption h2 {
  color: #fff;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  font-size: 35px;

}

.linetilte{
  height:5px;
  width: 100%;
  background-color: #da2f37;
  position: relative;
  margin-top: -9px;
}
.fixturecenter h2

{
  text-align: center;
  font-family: 'myFirstFont';
  font-size: 30px;
  margin-top: 4px;
}

.boldnew{
  font-weight: 500!important;
}
.fixturehome{
  padding: 0px;
}
.item{
padding:20px 0px 0px 0px;
border-radius: 10px;
background-color: #fff;
background-image: url('./Frame 375.png');
margin-right: 10px;
background-size: cover;
    background-position: top;
}
.fixturecard h6
{
  font-family: 'myFirstFont';
  color: #fff;
  font-size: 16px;
  text-align: center;
  width: 147px;
  margin: auto;
  border-radius: 5px;
}
.fixtureleft img, .fixtureright img{

}
.fixtureleft , .fixtureright
{
  padding: 10px;

}
.fixturecard p
{
  font-family: "poppins";
  color: #551702;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 17px;
  margin: 22px 0px 33px;
}
.stadium p{
 background: linear-gradient(0deg, rgba(149,27,33,1) 0%, rgba(208,62,69,1) 52%, rgba(149,27,33,1) 98%);
  ;
    color: #fff;
    font-family: 'myFirstFont';
    text-align: center;
    margin-top: 20px;
    padding: 5px;
}
.fixturecard img{
  width: 80%!important;
  margin: auto;
  display: block;
  border-radius: 23px;
}
.scorebox{
  background-color: #FFC51F;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  padding: 6px 0px 3px 0px;
}

.vertical-divider {
  border-right: 0.8px solid rgb(0 0 0 / 10%);
  }
.scorebox h5{
  color: #551702;
  text-align: center;
    font-family: "Oswald", sans-serif;
align-self: center;
font-weight: 500;
font-size: 22px;
padding: 3px 0px 0px 0px;
line-height: 23px;
/* letter-spacing: 10px; */
justify-content: center;
/* margin-left: 10px; */

}
.fixtureleft h3{
    font-family: 'myFirstFont';
  font-size:15px; 
  text-align: center;
  margin-top: 16px;
  color: #2a2c81;
  letter-spacing: 1px;
  width: 100%;
  font-weight: 500;
  /* white-space: nowrap; */
  /* float: right; */

}
.fixturecenter h3
{
  font-family: "Oswald", sans-serif;
  font-size:18px;
  text-align: center;
  color: #a8a8a8;
  margin-top: 16px;
}

.fixturecard h5{
  font-family: 'myFirstFont';
  margin-bottom: 20px;
  background: #9d2026;
  width: fit-content;
  text-align: center !important;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 5px 10px;
}
.highlight td:first-child {
  border-left: 8px solid #951b21 !important;
  margin-left: 30px;
}
.moreinfo{
  font-family: "Oswald", sans-serif;

  border: 1px solid #FFC51F;
  padding: 8px 10px;
  background-color: #fff;
  color: #2a2c81;
  margin: auto;
  display: block;
  margin-top: 22px;
  font-weight: 500;
  font-size: 14px;
}

thead th {
  font-weight: 500;
}


/* Add this CSS for the bounce effect */
.moreinfo:hover {
  background-color: #2a2c81;
  color: #fff;
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.modal-title{
  font-family: 'Poppins';
  color: #fff;
}

.modal-content
{
  background: url(https://dev-upkl.upkl.in//files/bgnew.png);
    background-size: contain;
}
/* point table */
.pointtable{
  /* background: linear-gradient(90deg, rgba(36,33,104,1) 0%, rgba(71,65,206,1) 100%); */
  background: #551702;
  background-image:url('./bluebg.png');
  padding:  60px;
  position: relative;
  BACKGROUND-POSITION: left;
  background-size: cover;
}

.pointtable::before {
  content: '';
  position: absolute;
  top: -90px;
  left: 0;
  width: 100%;
  opacity: .8;
  height: 100%;
  /* background: url('../src//mask.png') no-repeat center center/cover ;  */
}

tr,th {
  font-family:"Oswald", sans-serif;
  font-size: 14px;
  font-weight: 400;
  vertical-align: middle
}
thead th{
  background: linear-gradient(0deg, rgba(149,27,33,1) 0%, rgba(208,62,69,1) 52%, rgba(149,27,33,1) 98%)!important;
  color: #fff!important;
}
table{
  position: relative;
  z-index:999;
  width: 85%;
  margin: auto;
  margin-top: 57px;
}
table>:not(caption)>*>*{
  background:#fff;
  color: #fff!important;
  text-transform: uppercase;
  font-size: 20px;
  padding: 10px!important;
  font-family: 'myFirstFont';
  font-weight:500;
  letter-spacing: 1px;

}
.table>:not(caption)>*>*{
  box-shadow: none!important;
}

.readmore{
  border-radius: 20px;
  letter-spacing: 1px;
  border: 0px;
  font-family: 'myFirstFont';
  background: transparent;
  border: 1px solid #ddac12;
  padding: 8px 20px;
  color: #ffff;
}
.table-striped>tbody>tr:nth-of-type(odd)>*{
  background-color:#fff!important;
  color: #2f2b60  !important;
  font-family: 'myFirstFont';

}
thead tr{
  background-color:#EBEBEB!important;
}
.table-striped>tbody>tr:nth-of-type(even)>*{
  background-color:#fff;
  color: #2f2b60 !important;
}
table ul{
  list-style-type: none;
  display: flex;
  margin-bottom: 0px;

}

table ul li{
  margin-left: 10px;
}
.loss{
  height:30px;
  width:30px;
  background-color:#F3504F;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  line-height: 30px;
  font-family: 'poppins';

}

.win{
  height:30px;
  width:30px;
  background-color:#329553;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  line-height: 30px;
  font-family: 'poppins';


}

.table th:nth-child(1),
.table td:nth-child(1) {
  width: 5%; /* Adjust the width percentage as needed */
  font-weight:500;
}

.table th:nth-child(2),
.table td:nth-child(2) {
  width: 15%; /* Adjust the width percentage as needed */
}

.table th:nth-child(3),
.table td:nth-child(3) {
  width: 25%; /* Adjust the width percentage as needed */
  font-weight: 500;
}

.table th:nth-child(4), .table th:nth-child(5), .table th:nth-child(6),.table th:nth-child(7),.table th:nth-child(8),
.table td:nth-child(4),.table td:nth-child(5),.table td:nth-child(6),.table td:nth-child(7),.table td:nth-child(8) {
  width: 6%; /* Adjust the width percentage as needed */
}
table img{
  width: 100%;
  margin: auto;
  display: block;
  width: 66%!important;
}
.topsection{
 background-color: #551702; 
 max-height: 150px;
 border-top-left-radius: 5px;
 border-top-right-radius: 5px;
}
.playername h5{
  margin-top: -30px;
  font-size: 25px;
}
.topsection .logoteam{
  position: relative;
  width: 60%;

}

.topsection .playername{
  font-family: "Oswald", sans-serif;
  color: #fff;
margin-left: 20px;
}
.topsection .logoteam
{
  width: 45%!important;
  margin-left: 20px;
  top: -39px;
  z-index: 999;
}
.topsection .playerimg
{
  width: 40%!important;
  margin: auto;
  top: -50px;
  z-index: 0;
  position: absolute;
}
.bottomsection{
  background-color: #FFC51F;
  padding: 20px 10px 12px 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  z-index: 1;

}
.stats h5{
  font-family: "Oswald", sans-serif;
  color: #551702;
  text-align: center;
  font-size: 18px;
}
.stats h3{
  font-family: "Oswald", sans-serif;
  color: #551702;
  margin-top: 10px;
  text-align: center;
  font-size: 26px;
  font-weight: 500;
}
.itemstats{
  border-radius: 5px;
}
.playerstatssection{
  padding: 40px 0px 40px 60px;
  background-color: #2A2C81;

}
.playerstatssection .owl-stage-outer {
padding-top: 50px;
}


/* newsleter */

.newletter{
  /* padding: 40px 0px 40px 60px; */
  background-color: #551702;
}
.newletter  .line{
  height: 1px;
  width: 150px;
  background-color: #da2f37 ;
  margin: 10px 0px ;
}
.newletterright{
  padding: 0px 30px;
}
.newletterright h5{
  color: #fff;
  font-family: "Oswald", sans-serif;
font-size:38px;
line-height: 50px;
text-transform: uppercase;
width: 93%;
}
.newletterright p{
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  font-family: 'poppins';
  width: 90%;
  line-height: 25px;
  margin-top: 50px;
  margin-bottom: 30px;
}
.newlogos .date{
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  font-family: 'poppins';
  margin-top: 10px;
}

.whoweareleft img{
  margin: auto;
  animation: moveAnimation 4s linear infinite; /* Animation properties */

  display: block;
}

@keyframes moveAnimation {
  0% {
    transform: translateX(0); /* Start at original position */
  }
  50% {
    transform: translateX(40px); /* Move 20px to the right */
  }
  100% {
    transform: translateX(0); /* Back to original position */
  }
}
/* playerstatis */
.newarrows h2{
  font-family: 'myFirstFont';
  font-weight: 500;
  letter-spacing: 2px;
}
.breadcrumb-caption .textrightarrow {
  position: relative;
  left: 15px;
  top: 5px;


}
.image-container {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    /* background-image:linear-gradient(270deg, rgb(16 28 81 / 0%) 0%, rgb(42 44 129 / 67%) 100%); */
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  /* padding: 10px; */
  box-sizing: border-box;
}
.text {
  color: white;
  padding: 0px 10px;
  position: absolute;
  backdrop-filter: blur(50px);
  padding: 10px 12px;


}
.overlay h5{
  font-family: "Oswald", sans-serif;
  font-size: 28px;

}
.overlay p{
  font-size: 14px;
  font-weight: 200;
  font-family: 'poppins';
  margin-bottom: 0px;
  margin-top: 10px;
}
.divider{
  height: 1.2px;
  background-color: #da2f37 ;
  width: 80px;
  margin-top: 20px;
}
.image {
  display: block;
  width: 100%;
  height: auto;
}
.bordernew{
  position: absolute;
  top: 12px;
  right: 14px;
}

/* tv */

.card-body{
 padding: 5px 0px;
}
.card-body h5{
color: #fff;
font-family: 'myFirstFont';
font-weight: 400;
font-size: 20px;
}
.card-body p
{
  color: #fff;
  font-family: 'poppins';
  font-size: 14px;
  margin-bottom: 5px;
}
.datetv{
  font-weight: 200;
  font-size: 14px;
}

.tvsection  {
  padding: 40px 60px 40px 60px;
  background-image: url('./YELLOW RECT (3).png');
  background-size:cover;
  background-attachment: fixed;
}
.card{
  border: 0px;
}


/* boxerman */

.kabaddiman {
  position: relative;
  width: 100%;
  height: 70vh;
  background-image: url('./khali-poster.jpg');
  background-size: cover;
}

.kabaddiman::before {
  content: "";
  /* background: rgba(0, 0, 0, 0.5);  */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.overlay-content {
  position: absolute;
  color: white; /* Adjust text color */
}
.blog-details {
  padding: 25px;
  margin: -50px 0px 0px!important;
  z-index: 9999;
  background: #fff;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  z-index: 1;
  position: relative;

}
.ql-editor  br{
  display: none;
}
  footer{
    background: rgb(36,33,104);
background: #2f2b60;
    padding: 20px 40px;
  }
  .socialmenu ul{
    list-style-type: none;
    float: right;
  }
  .footerline{
  border-bottom:0.6px solid #da2f37 59;
  }
  .socialmenu ul li{
    margin-left: 22px;
  }
  .followus h2{
    color: #fff;
  font-family: "Oswald", sans-serif;
  font-size: 20px;
  }
  .followusclub{
    margin-right: 30px;
  }

  .followusclub h2{
    color: #fff;
    font-family: "Oswald", sans-serif;
    font-size: 20px;

  }
  .followus{
    width: fit-content;
    margin-left: 20px;
  }
  .followline{
    height: 2px;
    width: 100%;
    background-color: #da2f37 ;
  }
  .footerrightmenu{
    float: left;
    overflow-x: scroll;
    width: 100%;
  }
  .footermenu ul{
    list-style-type: none;margin-top: 40px;
    margin-bottom: 20px;
  }
  .footerrightmenu ul li{
margin-right: 10px;
list-style-type: none;
float: right;
align-self: center;

  }
  .footermenu ul li{
    margin-left: 20px;
    color: #fff !important;
    font-family: 'poppins';
    font-size: 15px;
    font-weight: 200;
  }
  .footerlogo img{
    margin: auto;
    display: block;
    width: 90px;
  }
  .footerlogo p{
    color: #fff;
    font-family: 'poppins';
    font-weight: 300;
    text-align: center;
    margin-top: 20px;
    font-size: 13px;
  }
  .match-score-tl h5{
    font-size: 14px!important;
  }
  .policy{
    color: #FFF;
    font-family: 'poppins';
    font-weight:light;
    text-align: center;
    font-size: 14px!important;
    margin-top: 20px;
    margin-bottom: 0px;

    font-weight: 300;
  }



  /* teampage  */
  .breadcrumb {
    position: relative;
    margin: 0;
    height: auto!important;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 300px;
    align-items: center;
    background-image: url('./Frame\ 373\ \(2\)\ \(1\).png');
    background-size: contain;
    background-color: rgb(16,12,57);;
  }
  
 
  .breadcrumb img {
    display: block;
    width: 100%; /* Ensures the image fills its container */
    z-index: -1;
  }
  .breadcrumb-caption h3{
    color: #fff;
  font-family: "Oswald", sans-serif;
  font-size: 30px;
  text-align: center;
  }
  
  .breadcrumb-caption .lines{
    height: 3px;
    background-color: #da2f37 ;
  }

  .breadcrumb-caption {
    position: absolute;
    bottom: 35px!important;
    width: fit-content;
    text-align: center; /* Aligns text to the center */
    color: white; /* Text color */
    padding: 10px 0; /* Adjust padding as needed */
  }
  

  /* teams page */
  .teamsection{
    padding: 50px 0px;
    background-color: #551702;
  }
  .topsection {
    position: relative;
  }
  .topsection1{
    background-color: #ececec!important;
  }
  .topsection .overlayplayer {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-family: 'Oswald';
    font-weight: 500;
    font-size:24px; /* adjust font size as needed */
    white-space: nowrap;
  }
  .topsection img{
    width: 100%;
    background-color: transparent;
  }
  .topsection h3
  {
    text-align: center;
    font-family: 'Oswald';
    color: #551702;
  }
  .teamcard{
    background-color: #ECECEC;
    margin: 20px 0px;
    
  }
  .bottomplaysection img{
    margin:-40px auto 30px ;
    display: block;
    width: fit-content;
    position: relative;
    width: 65%;
  }
  .bottomplaysection{
    padding: 22px 16px;
  }
  .teamsection button
  {
    color: #551702;
    background-color: #ffff;
    padding: 10px 15px;
    border: 1.2px solid #551702;
    font-family: "Oswald", sans-serif;
    font-weight: 500;
    font-size: 15px;
  }
  .btnteams
  {
    justify-content: space-evenly;
  }
  

  .matchhead{
    font-size: 20px!important;
  }

  /* matches page */
  .matchstrip{
    background-color: #551702;
    padding: 50px 40px;
  }

 

  .matchleft h5{

    color: #fff;
    font-family: "Oswald", sans-serif;
    font-size: 17px;
    /* margin-top: 20px; */
    border-right:2px solid #ffffff52;
  }
.matchesbox
  { background: linear-gradient(180deg, rgba(42,44,129,1) 50%, rgba(66,69,185,1) 100%);
    height: 100px;
    margin-bottom: 20px;
    padding: 12px 30px;
    width: 100%;
    transition: width 0.3s ease;


  }


  .matchstrip .titlecaption {
margin-top: 40px;
  }

  
  .matchesbox:hover {
  width: 101%;
  background: linear-gradient(180deg, rgba(66,69,185,1) 0%, rgba(42,44,129,1) 50%, rgba(66,69,185,1) 100%);

  }
  
  .matcharrow {
    transition: right 0.8s ease;
  }
  
  .matchesbox:hover .matcharrow {
    right: -58px;
    /* Move the arrow 20px to the left on hover */
  }
  .matchseparator{
    height: 0.6px;
    width: 100%;
    background-color: #ffffff3b;
    margin-bottom: 15px;
  }
  .matchright p{
    color: #fff;
font-family: 'poppins';
font-size: 14px;
font-weight: 200;
margin-top: 20px;
  }
  .match-score h5{
    font-size: 28px;
    margin-bottom: -5px;
  }
  .match-score {
    margin-top: 10px;
  }
 
  .breadcrumb-caption{
    bottom: 6px;
  }
  .breadcrumb-caption h3{
    font-size: 30px;
  }

.matcharrow  {
  position: relative;
  float: right;
  right: -46px;
  top: -42px;
  width: 38% !important;
}
  .match-score {
    background-color: #FFC51F;
    color: #551702;
font-family: "Oswald", sans-serif;
text-align: center;
border-radius: 5px;
align-self: center;
width: 57px;
margin: auto;
display: block;


  }
 
  .matchleft h5{
    border: 0px;
  }
  .rightarrowmatch{
    float: right;
    position: relative;
    top: -3px;
  }
 .teamname h6{
    color: #fff;
font-family: "Oswald", sans-serif;
font-size: 17px;
text-align: center;
align-self: center;
margin-top: 15px;
  }

  .float-right {
    float: right;
  }

  .float-left {
    float: left;
  }
  .matchcenter  {
    border-left: 0.8px solid #ffffff2b;
    border-right: 0.8px solid #ffffff2b;
    margin: 5px 15px;

  }
.matchstrip img
  {
    width: 56%;
    margin: auto;
    display: block;
  }
  .match-score-tl{
    color: #fff;
    font-family: "Oswald", sans-serif;
    font-size: 12px;
    text-align: center;
    font-weight: 400;
  }
  .matchlogotop{
    
    width: 74px;
    height: 74px;
    border:2px solid #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;

  }
  .matchlogo {
    width: 67px;
    height: 67px;
    background-color: #D9D9D9;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
  }
  .verticalline{
    width: 0.8px;
    background-color: #fff;
    height: 40px;
  }


.newlist h6
{
color: #fff;
font-family: "Oswald", sans-serif;
font-size: 22px;
line-height: 28px;
font-weight: 400;
}

 .bottompara p{
  top:0px;
  color: #FFC51F;
  font-family: 'poppins';
  font-size: 15px;
  position: relative;
  margin-top: 20px;
 }

 .bottompara p span{
  color: #fff;
  font-family: 'poppins';
  font-size: 12px;
  font-weight: 200;
 }


 /* boxerman */
 .captioninfo h5{
  color: #fff;
  font-family: "Oswald", sans-serif;
  font-size: 40px;
  width: 70%;}

  .captioninfo
  {
    position: absolute;
    top: 150px;
    margin-top: 50px;

  }
  .kabaddiman .newletterright{
    padding: 90px 40px 80px 0px;
  }
  .captioninfo .line{
    height: 1px;
    width: 150px;
    background-color: #da2f37 ;
    margin: 10px 0px;
}


 .captpara p{
  color: #fff;
  width: 91%;
  font-family: "poppins";
  font-size: 18px;

 }
 .carousel-control-next-icon {
  background-size: 100% 100%;
  background-color: #ffffff33;
  padding: 16px;
  border-radius: 60px;
  background-image: url('https://dev-upkl.upkl.in//files/arrow copy.png')!important;

 }

 .carousel-control-prev-icon{
  background-size: 100% 100%;
  background-color: #ffffff33;
  padding: 16px;
  border-radius: 60px;
  background-image: url('https://dev-upkl.upkl.in//files/arrow.png')!important;
 }

 .carousel-control-next-icon{

 }
 iframe{
  height: 500px;
  border-radius: 8px;
 }
 .modal-body{
  padding: 10px!important;
 }
 .newpage{
  padding: 30px;
  background-color: #9f272c;
 }
 .newpage  img{
  margin-bottom: 20px;
  border-radius: 6px;
 }

.trophysection{
  background-size: cover;
  background-color: #551702;
  padding: 0px 40px;

}
.trophy-content p{
  font-size: 15px;
  color: #fff;
  font-family: 'poppins';
  font-weight: 300;
}
.trophy-content h5 {
  color: #fff;
  font-family: "Oswald", sans-serif;
  font-size: 32px;
  margin-top: 30px;
}

.trophy-content2 p{
  font-size: 15px;
  color: #fff;
  font-family: 'poppins';
  font-weight: 300;
}
.trophy-content2 h5 {
  color: #fff;
  font-family: "Oswald", sans-serif;
  font-size: 32px;
  margin-top: 30px;
}
.itemluck{
  border-radius: 5px;
}
.trophygallery{
  background-color: #551702;
}
.trophysection1{
  background-size: contain;
  height:1500px;
  width: 100%;
background-repeat: no-repeat;
}
.trophy-content {
  margin-top: 30%;
}

.stripcity{
  background: linear-gradient(180deg, rgba(222,165,1,1) 6%, rgba(255,197,31,1) 50%, rgba(222,165,1,1) 93%);
padding: 10px;
}
.stripcity ul{
  text-decoration: none;
  margin-bottom: 0px;
}
.touractive{
  background-color:#2B2D82 !important;
  color: #fff!important;
}

.touractive a{
  color: #fff!important;
}
.stripcity li a{
  color: #2B2D82;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.stripcity li{
  text-decoration: none;
  list-style-type: none;
  color: #fff!important;
  font-family: "Oswald", sans-serif;
  border: 1px solid #2B2D82;
  padding: 8px 15px;
  margin-right: 20px;
}
 
  @media only screen and (max-width: 600px) {
    .newpage{
      padding: 25px 15px;
    }
    iframe{
      height: 260px;
  
     }
      .newletterright h5 {
        color: #fff;
        font-family: "Oswald", sans-serif;
        font-size: 22px;
        width: 100%;
        line-height: 35px;
        margin-top: 20px;
    }
    .item{
      height: auto;
    }
    .carousel-control-next-icon {
      display: none;
    }
    .carousel-control-prev{
      display: none;
    }
    .bottompara p{
      font-size: 13px;
      top: 8px;
    }
    .kabaddiman{
      background-position: right!important;
      
    }
    
    .kabaddiman .newletterright{
padding: 25px 0px 0px 15px;
    }
    .newlist h6{
      font-size: 14px;
      line-height: 20px;
      
    }
    
.trophy-content2 {
  margin-top:20px!important
}
.trophy-content h5{
  font-size: 22px;
}
.trophy-content p{
  font-size: 12px!important;
}
   

    .newletterright p {
      color: #fff;
      font-size: 14px;
      font-weight: 300;
      font-family: 'poppins';
      width: 100%;
      line-height: 20px;
      width: 90%;
      margin-top: 33px;
    }
    .rightcontentnew{
      padding: 5px 10px 0px 0px;
    }
    .newletterright{
      padding: 0px;
    }

    .overlay h5{
      font-size: 22px;
      line-height: 30px;
    }
    .divider{
      margin-top: 10px;
    }
    .card-body h5 {
      font-weight: 400;
      font-size: 18px;
    }  
    .card-body p{
      font-size: 14px;
    }
    .datetv{
      font-size: 12px;

    }
    footer{
      padding: 20px 10px;
    }
    .policy{
      font-size: 9px!important;
      font-weight: 500!important;
    }
    .teamsection{
      padding: 12px;
    }
    .topsection{
      max-height: initial;
    }
    
    .bottomplaysection img {
      margin: -87px auto 39px;
      display: block;
      width: -moz-fit-content;
      width: fit-content;
      position: relative;
      width: 70%;
  }
  .sponsorsection{
    padding: 20px 7px!important;
  }
  .matchesbox{
    padding:10px 15px;
    height: auto;
  }
  
  .matchstrip img{
    width: 100%;
  }
  .match-score h5{
    font-size: 24px;
  }
  .teamcard{
    margin: 15px 20px;
  }
  .bloglist .card{
    margin:10px
  }
  .teamname h6{
    font-size: 12px;
    padding: 5px;
  }
  .match-score-tl h5{
    font-size: 12px;
  }
  .matchleft h5{
    height:30px;
    line-height: 22px;
  }
  .matchleft h5,.matchright p{
    text-align: center;
    margin-top: 12px;
    margin-bottom: 5px;
    list-style: 15px;
    font-size: 12px;
  }
  .matchleft h5{
    color: #ffffffa6;
    margin-top: 0px;
    font-size: 18px;

  }
  .mobilenone{
    display: none;
  }
  .row{
padding: 0px;
  }
  .row1{
    width: 100%;
    margin: 0px;
  }
  .footerrightmenu img{
    width: 52px;
    height: 52px;
  }
  .blogline{
    margin: 6px 0!important;
  }
  .blogdate, .blogshareicon{
    font-size: 12px!important;
    text-align: justify;
  }
  .vertical-divider{
    border-right:0px
  }
  .desktopnone{
    display: block;
  }
}




.borderimg
{
  position: absolute;
  width: 251px;
  right: 363px;
  margin-top: -240px;
}


.card{
  background-color: transparent!important;
}


/* single blog */
.bloglogo img{
  margin-top: 15px;
  width: 20px!important;
  height: auto;
  margin-left: 10px;
}
.blogpara p strong{
  font-family: "Oswald", sans-serif;
  font-size: 26px!important;
  font-weight: 500;
}
.blog-details{
  padding-top: 40px;
  margin: 50px 0px;

}
.blogbanner img{
width: 100%;
}
.blogheadng {
  padding: 0px;
}
.blogheadng h2
{
  color:#202020;
  padding: 0px;
  font-family: "Oswald", sans-serif;
  font-size: 40px;
  text-transform: capitalize;
}
.blogdate , .blogshareicon{
  font-family: 'poppins';
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
}
.blogpara h2
{
  color:#202020;
  font-family: "Oswald", sans-serif;
  font-size: 32px;
}
.blogline{
  width: 130px;
  height: 2px;
  background-color: #da2f37 ;
  margin: 25px 0px;
}

.blogpara p
{
  color:#272727;
  font-family: "poppins";
  font-size: 16px;
  margin-top: 25px;
}


.highlightword{
   color: #e2b324!important;
}
/* about us page  */

.aboutsection{
  background:#191549;
  background-size: cover;
  padding: 40px 20px 0px 20px;
}

.aboutsection{
  margin: auto;
  display: block;
}
.aboutuspara p{
  color: #fff;
  font-family: 'poppins';
  font-size: 16px;
  font-weight: 300;
  width: 90%;
}

.aboutseparate{
  padding: 40px;
}

.ourmissionbox {
  padding: 30px 50px;
  background: linear-gradient(0deg, rgba(149,27,33,1) 0%, rgba(208,62,69,1) 52%, rgba(149,27,33,1) 98%);
  margin: 20px 0px;
  background-size: cover;
  background-repeat: no-repeat;
}
.ourmissionbox p{
  color: #fff;
  font-family: 'poppins';
  font-weight: 200;
  margin-top: 30px;
}
.btnppintteble{
  background-color: #ECC440;
}
.ourmissionbox .title{
  color: #fff;
  padding:1px 20px;
  background: #191549;
  font-weight: 500;
  margin: auto;
  display: block;
  width: fit-content;
  font-family: "Oswald", sans-serif;
  font-size: 21px;
}


.custom-container {
  position: relative;
  width: 100%;
  height: 80vh; /* Adjust height as needed */
  overflow: hidden;

}

/* Background image */
.custom-background-image {
  width: 100%;
  height: 100%;
  background-image: url('../src/About/playerimg.svg'); /* Replace 'your-image-url.jpg' with the URL of your image */
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

/* Black overlay */
.custom-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.091); 
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

/* Content on the left side */
.custom-content {
  position: absolute;
  top: 30%;
  left: 5%; /* Adjust the left position as needed */
  color: white;
  z-index: 2;
  font-family: "Oswald", sans-serif;
  line-height: 50px;

}
.custom-content h2{
line-height: 60px;
}
/* Play button with pulse effect on the right side */
.custom-play-button {
  width: 90px;
  position: absolute;
  top: 35%;
  right: 10%; /* Adjust the right position as needed */
  transform: translate(-50%, -50%);
  z-index: 2;
  animation: custom-pulse 2s infinite; /* Adjust animation duration as needed */
}
.aboutfounder{
  background-color: #242168;
  padding: 40px;
}

.founderbox{
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 50px;
  border-radius: 8px;
  padding: 20px 30px
}
.foundermessage1{
padding: 0px 30px!important;
}

.founderright{
  padding: 20px;
}


.foundermessage{
  padding: 0px;
}

.foundermessage p{
  color: #fff;
  font-family: 'poppins';
  font-weight: 200;
  margin-top: 30px;
  width: 100%;
  font-size: 17px;

}
.founderimg img{
  position: relative;
  margin: auto;
  display: block;
}
.designation{
  margin-top: 20px;
}
.designation h5{
  color: #fff;
  text-transform: uppercase;
  font-family: "Oswald", sans-serif;
font-size: 20px;
font-weight: 400;
}

.designation h6{
  color: #fff;
  text-transform: uppercase;
  font-family: "poppins";
font-size: 16px;
font-weight: 200;
}

.founderline{
  height: 3px;
  width: 80px;
  background-color: #da2f37 ;
  margin-top: 20px;
  margin-bottom: 20px;

}
/* Pulse animation */
@keyframes custom-pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }
}
.custom-content h1{
font-size: 22px;
}

.custom-content h1{
  font-size: 44px;
  }
.aboutsection .titlecaption{
  margin-bottom: 30px;
}

  /* bloglist */
  .bloglist{
    padding: 40px 0px ;
    border-bottom: 1px solid #e0d7d794;
  }
  .bloglist .card-img-top {
    border-top-left-radius: 0px!important;
    border-top-right-radius: 0px!important;

  }
  .bloglist .card-body
  {
    background: linear-gradient(117deg, rgba(48,50,142,1) 41%, rgba(34,31,92,1) 100%);
    padding: 10px 15px!important;
  }
  .bloglist  .card-img-top{
    border: 0px;
  }
  .bloglist .card-body h5{
    margin-top: 15px;
    line-height: 24px;
    font-size: 21px;
  }

  .readmore{
    margin-top: 15px;
  }
  .readmore button
  {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    line-height: 0px;
    border: 0px;
    padding: 7px 14px;
    border-radius: 14px;
    background: linear-gradient(173deg, rgba(222,165,1,1) 41%, rgba(255,197,31,1) 100%);
      }
  .readmore p{
    color: #fff;
    margin-top: 5px;
  }
  .trophy-content2 {
  margin-top: 50%;
  }
  .fixturessection1{
    padding: 40px 20px;
    background-color: #551702;
  }
@media only screen and (max-width: 600px) {
  .aboutsection{
    padding: 40px 0px;
  }
  .trophysection1{
    height: auto;
  }
  .trophy-content h5{
    margin-top: 460px;
  }
  .trophysection{
    padding: 0px 10px;
  }
  .trophy-content2 h5{
    font-size: 22px;
  }
  .trophy-content2  p{

    font-size: 12px!important;
  }
  .trophy-content{
    top:40%;
  }
  .stripcity{
    width: 100%;
    overflow-x: scroll;
  }
  .row{
    padding: 0px;
  }
 .titlecaption  .matchhead{
    font-size: 18px!important;
  }
  .breadcrumb-caption .abc{
    display: none;
  }
  .matchstrip{
    padding: 4px 10px;
  }
  .founderimg img{
    margin-top: 20px;
    width: 100%;
    margin: auto;
    display: block;
    float: none;
  }
  .founderright{
    padding:0px;
  }
  .foundermessage p{
    width: 100%;
    font-size: 14px;
    margin-top: 15px;
  }
  .founderline{
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .foundermessage{
    padding: 10px;
  }
  .foundermessage1{
    padding: 0px 5px!important;
  }
  .founderbox{
    padding: 20px 15px;
    border-radius: 12px;
    margin: 40px 10px 0px 10px;
  }
  .aboutsection .titlecaption{
    margin-bottom: 20px;
  }
  .whoweareleft{
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .ourmissionbox{
    padding: 30px 20px;
  }
  .custom-content {
    left: 10%;
  }
  .aboutuspara p{
    font-size: 14px;
    font-weight: 300;
    width: 100%;
  }
  .aboutseparate{
    padding: 10px;
  }
  .ourmissionbox{
    margin: 10px 5px; 
  }
  .aboutfounder{
    padding: 30px 10px;
  }
  .founderbox{
    margin: 10px 0px;
  }
  .custom-container{
    height: 25vh;
  }
  table>:not(caption)>*>*{
    font-size: 13px;
  }

  .footermenu p{
    margin-bottom: 8px;
  }
  .custom-content h1{
    font-size: 17px;
    line-height: 22px;
    }
    .custom-play-button{
      width: 56px;
    }
    .ourmissionbox p{
      font-size: 14px;
    }
    .foundermessage{
      padding: 0px;
    }












  .sponsorsection .title h6 {
    font-size: 18px;
  }
  .footerlast{
    font-size: 14px!important;
  }
  .breadcrumb img{
    height: 10vh!important;
  }
  .breadcrumb-caption{
    bottom: 2px!important;
  }
  .breadcrumb-caption h2{
    font-size: 20px;
    margin-bottom: 7px;
  }
  .ml-auto a{
    margin-left: 22px;
  }
  .navbar{
    padding: 10px 0px;
   }
   .navbar-collapse{
    background-color: #da2f37 !important;
    z-index: 999999;
   }
   .navbar li a{
    color: #551702;
   }
   .navbar-toggle{
    border: 0px;
   }


   #mobileMenuOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: none;
}

.mobile-menu {
    position: absolute;
    top: 0;
    left: -80%;
    width: 80%;
    height: 100%;
    background-color: #fff;
    transition: left 0.3s ease;
    z-index: 1001;
    padding: 20px;
}

.mobile-menu .close-menu {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
}

.mobile-menu-items {
    list-style-type: none;
    padding: 0;
}

.mobile-menu-items li {
    margin-bottom: 10px;
}

.mobile-menu-items li a {
    color: #333;
    text-decoration: none;
    font-size: 18px;
}



.blog-details{
  margin: -24px 0px 0px!important;
}

#menuToggle {
  display: block;
  flex-direction: column;
  position: relative;
  top: 15px;
  left: -20px;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  width: 300px;
}

#menuToggle input
{
  display: flex;
  width: 25px;
  height: 25px;
  position: absolute;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
}
table img{
  width: 60px!important;
}
.blog-details{
  z-index: 0;
}
#menuToggle span
{
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}
.footerrightmenu ul li{
  margin-right: 5px
}
#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-3px, -1px);
  background: #fff;
}
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

#menu
{
  overflow-y: hidden;
  position: absolute;
  width: 180px;
  height: 100vh;
  font-family: "Oswald", sans-serif;
  margin: -50px 0 0 -70px;
  padding: 20px;
  padding-top: 75px;
  background-color: #551702;
  transform-origin: 0% 0%;
  transform: translate(-100%, 0);
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

#menu li
{

  padding: 10px 0;
  transition-delay: 2s;
  list-style-type: none;
  text-align: left;
  text-decoration: none;
  margin-left: 30px;
}

#menuToggle input:checked ~ ul
{
  transform: none;
}
ul{
  list-style-type: none;

}
#menu{
  width: 100%;
}
#menu li a{
  color:#fff ;
  font-family: "Oswald", sans-serif;
  font-size: 14px;
}
.mblogo img{
  margin: auto;
  display: block;
  width: 16%;
  top: 0px;
  margin-top: -20px;
  z-index: 999999;
  position: relative;
}
.blog-details{
  padding: 33px 15px 10px 26px!important;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.blogpara p strong{
  font-size: 18px!important;
}
.blogheadng h2 ,.blogpara h2{
  font-size: 20px;
}
.blogpara p{
  margin-top: 20px;
}
.titlecaption h2{
  font-size: 20px!important;
}
.topsection .overlayplayer{
    top: 25%;
    font-size: 30px;
}
.textrightarrow {
  position: relative;
  left: 11px;
  top: 3px;
  width: 76%;
}
.owl-dots{
  display: none;
}
.matchcenter {
  border-left: 0px solid #ffffff2b;
  border-right: 0px solid #ffffff2b;
  margin: 0px;
}
.carousel-control-prev-icon , .carousel-control-next-icon{
  display: none!important;
}


.kabaddiman::before {
  content: "";
  background: rgba(0, 0, 0, 0.5); 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
}




.preheader {
  overflow: hidden;
}

.preheadingtext p {
  animation: marquee 10s linear infinite; /* Adjust duration as needed */
}



@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

